const setDefaultMetas = (mts) => {
    window.defaultMetas = {
        "app_title": mts.title,
        "titulo": mts.title,
        "descricao": mts.description,
        "imagem": `${mts.imagem.generic}.jpg?size=1200`,
        "keywords": mts.keywords,
        "width": "1200",
        "height": "630",
        "type": "website",
        "card": "summary",
        "twitter": ""
    }
}

const callForMetaUpdate = (metas) => {
    let mts = { ...window.defaultMetas, ...metas };
    document.title = mts.titulo;
    if (document.getElementById("mainTitle")) {
        document.getElementById("mainTitle").innerHTML = mts.titulo;
    }
    let infos = [
        { name: "description", value: mts.descricao },
        { name: "image", value: mts.imagem },
        { name: "keywords", value: mts.keywords },
        { name: "og:title", value: mts.titulo },
        { name: "og:description", value: mts.descricao },
        { name: "og:image", value: mts.imagem },
        { name: "og:image:width", value: mts.width },
        { name: "og:image:height", value: mts.height },
        { name: "og:url", value: window.location.href },
        { name: "og:site_name", value: mts.app_title },
        { name: "og:type", value: mts.type },
        { name: "twitter:card", value: mts.card },
        { name: "twitter:site", value: mts.twitter },
        { name: "twitter:title", value: mts.titulo },
        { name: "twitter:description", value: mts.descricao },
        { name: "twitter:image", value: mts.imagem },
    ];

    infos.forEach(info => updateMeta(info));
}

const updateMeta = (i) => {
    let meta = false;
    meta = document.querySelector(`meta[name="${i.name}"]`);
    if (!meta) {
        meta = document.createElement("meta");
        meta.setAttribute("name", i.name);
        meta.setAttribute("property", i.name);
        document.head.appendChild(meta);
    }
    meta.setAttribute("content", i.value);
}

export {
    callForMetaUpdate,
    setDefaultMetas
}