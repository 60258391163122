import "./index.scss";
import { Link } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="footer" className="bg-primary">
            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <div className="d-flex f-col gap-25">
                            <img className="logo" src={`${localStorage.getItem('apiUrl')}imagens/medium/logo.webp`} alt="Logo" />
                            <p className="p3 txt-primary-contrast" dangerouslySetInnerHTML={{ __html: resumo.textos.footer }}></p>
                            <div className="sociais d-flex">
                                {resumo.configuracoes && resumo.configuracoes.facebook &&
                                    <a href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                        <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/facebook.webp`} alt="Facebook" />
                                    </a>
                                }
                                {resumo.configuracoes && resumo.configuracoes.instagram &&
                                    <a href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                        <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/instagram.webp`} alt="Instagram" />
                                    </a>
                                }
                                {resumo.configuracoes && resumo.configuracoes.warehouse &&
                                    <a href={resumo.configuracoes.warehouse} target="_blank" rel="noopener noreferrer" title="3D Warehouse">
                                        <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/icon.webp`} alt="Drive" />
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-2"></div>
                    <div className="col-2 desktop">
                        <p className="h4 txt-primary-contrast">Menu</p>
                        <div className="d-flex f-col gap-10">
                            <Link to="/" className={`p2 txt-primary-contrast txt-danger__hover`}>Home</Link>
                            <Link to="/batrol-moveis" className={`p2 txt-primary-contrast txt-danger__hover notranslate`}>A Batrol</Link>
                            <Link to="/produtos" className={`p2 txt-primary-contrast txt-danger__hover`}>Produtos</Link>
                            <Link to="/blog" className={`p2 txt-primary-contrast txt-danger__hover`}>Blog</Link>
                            <Link to="/representantes" className={`p2 txt-primary-contrast txt-danger__hover`}>Nossos representantes</Link>
                        </div>
                    </div>
                    <div className="col-2">
                        <p className="h4 txt-primary-contrast">Links</p>
                        <div className="d-flex f-col gap-10">
                            <Link to="/catalogos" className={`p2 txt-primary-contrast txt-danger__hover`}>Catálogos</Link>
                            <Link to="/fale-conosco" className={`p2 txt-primary-contrast txt-danger__hover`}>Contato</Link>
                            <Link to="/minha-conta" className={`p2 txt-primary-contrast txt-danger__hover`}>Seja nosso representante</Link>
                            <Link to="/politica-de-privacidade" className={`p2 txt-primary-contrast txt-danger__hover`}>Política de Privacidade</Link>
                            <Link to="/termos-de-uso" className={`p2 txt-primary-contrast txt-danger__hover`}>Termos de uso</Link>
                        </div>
                    </div>
                    <div className="col-3 desktop">
                        <p className="h4 txt-primary-contrast">Onde estamos</p>
                        <div className="d-flex f-col gap-10">
                            <p className="p2 txt-primary-contrast txt-weight-500">Telefone: <span className="txt-weight-400">{resumo.configuracoes.telefone}</span></p>
                            <p className="p2 txt-primary-contrast txt-weight-500">Depto de Vendas: <span className="txt-weight-400">{resumo.configuracoes.vendas}</span></p>
                            <p className="p2 txt-primary-contrast txt-weight-500">Whatsapp: <span className="txt-weight-400"><a className="txt-white" style={{ textDecoration: "underline" }} href={`https://wa.me/55${resumo.configuracoes.whatsapp.replace(/\D/g,'')}`} target="_blank" rel="noopener noreferrer">{resumo.configuracoes.whatsapp}</a></span></p>
                            <p className="p2 txt-primary-contrast txt-weight-500">E-mail: <span className="txt-weight-400">{resumo.configuracoes.email}</span></p>
                            <p className="p2 txt-primary-contrast txt-weight-400">{resumo.configuracoes.endereco} - {resumo.configuracoes.bairro}, {resumo.configuracoes.cidade} / {resumo.configuracoes.estado} - CEP {resumo.configuracoes.cep}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="creditos d-flex f-row justify-space-between align-center">
                    <p className="p2 txt-primary-contrast"><span className="notranslate">Batrol</span> - CNPJ {resumo.configuracoes.cnpj} - Todos os direitos reservados © {new Date().getFullYear()}</p>
                    <a className="p2 txt-primary-contrast" href="https://fw2propaganda.com.br" target={"_blank"} rel="noopener noreferrer" title="Gustavo Barbosa">FW2 Propaganda</a>
                </div>
            </div>
        </div>
    )
}

export default Footer;