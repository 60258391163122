import "./index.scss";
import { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Context from "../../Context";
import GoogleTranslate from "../GoogleTranslate";

const Header = () => {
    const { menuOpen, setMenuOpen, resumo, cart } = useContext(Context);
    const [menuProdutos, setMenuProdutos] = useState(false);
    const [listener, setListener] = useState(false);
    const [produtosListener, setProdutosListener] = useState(false);

    useEffect(() => {
        if (!listener) {
            setListener(true);
            document.addEventListener("click", (event) => {
                let isClickInside = document.querySelector("#header .menu > div").contains(event.target);
                let isClickToggler = document.querySelector("#mobileToggler").contains(event.target);

                if (!isClickInside && !isClickToggler) {
                    setMenuOpen(false)
                }
            })
        }

        if (!produtosListener) {
            setProdutosListener(true);
            document.addEventListener("click", (event) => {
                let isClickInside = document.querySelector("#header .prodmenu > div").contains(event.target);
                let isClickToggler = document.querySelector("#deskToggler").contains(event.target);

                if (!isClickInside && !isClickToggler) {
                    setMenuProdutos(false)
                }
            })
        }
    }, [menuProdutos, menuOpen]);

    return (
        <div id="header" className={`d-flex align-center justify-center ${window.location.pathname === `/` ? `bg-white` : `bg-primary`}`}>
            <div className="container large">
                <div className="d-flex justify-end justify-space-sm-between border-bottom align-center">
                    <div className="sociais d-flex f-row align-center">
                        {resumo.configuracoes && resumo.configuracoes.facebook &&
                            <a href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/facebook${window.location.pathname === `/` ? `B` : ``}.webp`} alt="Facebook" />
                            </a>
                        }
                        {resumo.configuracoes && resumo.configuracoes.instagram &&
                            <a href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/instagram${window.location.pathname === `/` ? `B` : ``}.webp`} alt="Instagram" />
                            </a>
                        }
                        {resumo.configuracoes && resumo.configuracoes.warehouse &&
                            <a href={resumo.configuracoes.warehouse} target="_blank" rel="noopener noreferrer" title="3D Warehouse">
                                <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/icon${window.location.pathname === `/` ? `B` : ``}.webp`} alt="Drive" />
                            </a>
                        }
                        {resumo.configuracoes && resumo.configuracoes.drive &&
                            <a href={resumo.configuracoes.drive} target="_blank" rel="noopener noreferrer" title="Google Drive">
                                <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/drive${window.location.pathname === `/` ? `B` : ``}.webp`} alt="Drive" />
                            </a>
                        }
                        {resumo.configuracoes && resumo.configuracoes.mooble &&
                            <a href={resumo.configuracoes.mooble} target="_blank" rel="noopener noreferrer" title="Mooble">
                                <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/mooble${window.location.pathname === `/` ? `B` : ``}.webp`} alt="Mooble" />
                            </a>
                        }
                        {resumo.configuracoes && resumo.configuracoes.youtube &&
                            <a href={resumo.configuracoes.youtube} target="_blank" rel="noopener noreferrer" title="YouTube">
                                <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/youtube${window.location.pathname === `/` ? `B` : ``}.webp`} alt="YouTube" />
                            </a>
                        }
                    </div>
                    <GoogleTranslate />
                </div>
                <div className="d-flex align-center justify-space-between">
                    <div className="d-flex align-center justify-flex-start gap-25">
                        <div className="logo">
                            <Link to="/" title="Home"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/logo.webp`} alt="Logo" /></Link>
                        </div>

                    </div>
                    <div className="mobile align-center justify-flex-end gap-10">
                        <Link to="/finalizar-pedido" title="Carrinho"><img src={`${localStorage.getItem('apiUrl')}imagens/thumb/cart${window.location.pathname === `/` ? `B` : ``}.webp`}
                            alt="Carrinho" /><span className="cartlength">{cart.length}</span></Link>
                        <Link to="/minha-conta" title="Minha conta"><img src={`${localStorage.getItem('apiUrl')}imagens/thumb/users${window.location.pathname === `/` ? `B` : ``}.webp`}
                            alt="Minha conta" /></Link>
                        <img onClick={() => setMenuOpen(true)} src={`${localStorage.getItem('apiUrl')}imagens/thumb/menu${window.location.pathname === `/` ? `B` : ``}.webp`}
                            alt="Menu" id="mobileToggler" />
                    </div>
                    <div className={`menu ${menuOpen ? 'active' : ''}`}>
                        <div className="d-flex f-row align-center justify-flex-end gap-30">
                            <p className="h3">Menu</p>
                            <div className="links d-flex f-row align-center justify-flex-end gap-60">
                                <Link onClick={() => setMenuOpen(false)} to="/" className={`p2 ${window.location.pathname === `/` ? `txt-primary-shade txt-primary__hover` : `txt-white txt-light-200__hover`}`}>Home</Link>
                                <Link onClick={() => setMenuOpen(false)} to="/batrol-moveis" className={`p2 notranslate ${window.location.pathname === `/` ? `txt-primary-shade txt-primary__hover` : `txt-white txt-light-200__hover`}`}>A Batrol</Link>
                                <Link onClick={() => setMenuOpen(false)} to="/produtos" className={`p2 ${window.location.pathname === `/` ? `txt-primary-shade txt-primary__hover` : `txt-white txt-light-200__hover`}`}>Produtos</Link>
                                <Link onClick={() => setMenuOpen(false)} to="/blog" className={`p2 ${window.location.pathname === `/` ? `txt-primary-shade txt-primary__hover` : `txt-white txt-light-200__hover`}`}>Blog</Link>
                                <Link onClick={() => setMenuOpen(false)} to="/representantes" className={`p2 ${window.location.pathname === `/` ? `txt-primary-shade txt-primary__hover` : `txt-white txt-light-200__hover`}`}>Nossos representantes</Link>
                            </div>
                            <div className="icons d-flex f-row align-center justify-flex-end gap-10">
                                <Link to="/finalizar-pedido"><img src={`${localStorage.getItem('apiUrl')}imagens/thumb/cart${window.location.pathname === `/` ? `B` : ``}.webp`} alt="Carrinho" /><span className="cartlength notranslate">{cart.length}</span></Link>
                                <Link to="/minha-conta"><img src={`${localStorage.getItem('apiUrl')}imagens/thumb/users${window.location.pathname === `/` ? `B` : ``}.webp`} alt="Minha conta" /></Link>
                                <img id="deskToggler" onClick={() => setMenuProdutos(true)} src={`${localStorage.getItem('apiUrl')}imagens/thumb/menu${window.location.pathname === `/` ? `B` : ``}.webp`} alt="Menu" />
                            </div>
                        </div>
                    </div>
                    <div className={`prodmenu ${menuProdutos ? 'active' : ''}`}>
                        <div className="d-flex f-row align-center justify-flex-end gap-30">
                            <p className="h3">Produtos</p>
                            <div className="links d-flex f-row align-center justify-flex-end gap-60">
                                {resumo.categorias.map(categoria =>
                                    <div key={`cats-header-${categoria.id}`} className={`h4 has-submenu txt-primary-shade txt-primary__hover`}><span>{categoria.nome} <img src={`${localStorage.getItem('apiUrl')}imagens/medium/arrow.svg`} alt="Arrow" /></span>
                                        <div>
                                            {categoria.linhas.map(linha => <Link key={`linha-submenu-${linha.id}`} onClick={() => setMenuProdutos(false)} to={`/produtos?linha=${linha.slug}`} className={`p2`}>{linha.nome}</Link>)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default withRouter(Header);



