import React, { useEffect, useRef } from 'react';
import './index.scss';

const GoogleTranslate = () => {
    const comboGoogleTradutorRef = useRef(null);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: 'pt',
                includedLanguages: 'pt,en,es',
                layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL
            },
            'google_translate_element'
        );

        comboGoogleTradutorRef.current = document.getElementById('google_translate_element').querySelector('.goog-te-combo');
    };

    useEffect(() => {
        const addScript = document.createElement('script');
        addScript.setAttribute(
            'src',
            'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    const changeEvent = (el) => {
        if (el.fireEvent) {
            el.fireEvent('onchange');
        } else {
            const evObj = document.createEvent('HTMLEvents');
            evObj.initEvent('change', false, true);
            el.dispatchEvent(evObj);
        }
    };

    const trocarIdioma = (sigla) => {
        if (sigla === 'pt') {
            comboGoogleTradutorRef.current.value = sigla;
            changeEvent(comboGoogleTradutorRef.current); // Dispara a troca
            window.location.reload(); // Recarrega a página para retornar ao padrão
        } else {
            comboGoogleTradutorRef.current.value = sigla;
            changeEvent(comboGoogleTradutorRef.current); // Dispara a troca
        }
    };

    return (
        <>
            <div id="google_translate_element" className="boxTradutor"></div>
            <div className="traducao">
                <a href="javascript:void(0);" onClick={() => { trocarIdioma('pt'); }}>
                    <img class="img-rounded" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Brazil.svg/1280px-Flag_of_Brazil.svg.png" alt="Bandeira do Brasil" />
                </a>
                <a href="javascript:void(0);" onClick={() => { trocarIdioma('es'); }}>
                    <img class="img-rounded" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/1280px-Flag_of_Spain.svg.png" alt="Bandeira da Espanha" />
                </a>
                <a href="javascript:void(0);" onClick={() => { trocarIdioma('en'); }}>
                    <img class="img-rounded" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1280px-Flag_of_the_United_States.svg.png" alt="Bandeira dos Estados Unidos" />
                </a>
            </div>
        </>
    );
};

export default GoogleTranslate;
